
import TenancyTenants from "~/graphql/Portfolio/TenancyTenants.gql";

import PropertyCardSkeleton from "~/components/EntityCard/PropertyCardSkeleton.vue";

import { filter } from "~/composables/useFilter";
import { InputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";
import { getCurrentTenant } from "~/helpers/portfolio/portfolio-tenancy-helpers";
import { getRemainingTenants, getExternalIdForTenant, getMonthlyRentForTenant } from "~/helpers/portfolio/portfolio-tenant-helpers";

export default {
  components: { PropertyCardSkeleton },

  inheritAttrs: false,

  props: {
    tenancyId: {
      type: String,
      required: true,
    },
  },

  apollo: {
    tenancy: {
      query: TenancyTenants,
      variables() {
        return new InputBuilder().setId(this.tenancyId).setInput("rentCategory", "RENT").build();
      },
      update(data) {
        return data.assetManagementTenancy;
      },
    },
  },

  data() {
    return {};
  },

  computed: {
    filter() {
      return filter;
    },

    loading() {
      return this.$apollo.queries.tenancy.loading;
    },

    tenants() {
      if (this.tenancy == null) {
        return null;
      }

      return this.tenancy.assetManagementTenants.items;
    },

    current() {
      if (this.tenants == null) {
        return null;
      }

      return getCurrentTenant(this.tenancy);
    },

    remaingTenants() {
      if (this.tenants == null) {
        return [];
      }

      return getRemainingTenants(this.current, this.tenants);
    },

    currentContract() {
      const start = filter.date.short(this.current.moveInDate);
      const end = "now";

      return `${start} - ${end}`;
    },

    currentLengthOfContractDays() {
      return filter.timefrom(this.current.moveInDate);
    },

    currentId() {
      return this.getId(this.current);
    },

    currentRent() {
      return getMonthlyRentForTenant(this.current);
    },

    currentCurrency() {
      return this.current.currency;
    },
  },

  methods: {
    getId(tenant) {
      return getExternalIdForTenant(tenant);
    },

    getMonthlyRentForTenant(tenant) {
      return getMonthlyRentForTenant(tenant);
    },

    filterCurrency(value) {
      return this.filter.currency(value, { currency: this.currentCurrency });
    },
  },
};
